// PlayerSticker.jsx

import React from 'react';
import './playersticker.css'; // Import the CSS for PlayerSticker styles

const nbaTeamLinks = {
  "Atlanta Hawks": "https://www.nba.com/stats/players/usage?TeamID=1610612737&dir=A&sort=MIN",
  "Boston Celtics": "https://www.nba.com/stats/players/usage?TeamID=1610612738&dir=A&sort=MIN",
  "Brooklyn Nets": "https://www.nba.com/stats/players/usage?TeamID=1610612751&dir=A&sort=MIN",
  "Charlotte Hornets": "https://www.nba.com/stats/players/usage?TeamID=1610612766&dir=A&sort=MIN",
  "Chicago Bulls": "https://www.nba.com/stats/players/usage?TeamID=1610612741&dir=A&sort=MIN",
  "Cleveland Cavaliers": "https://www.nba.com/stats/players/usage?TeamID=1610612739&dir=A&sort=MIN",
  "Dallas Mavericks": "https://www.nba.com/stats/players/usage?TeamID=1610612742&dir=A&sort=MIN",
  "Denver Nuggets": "https://www.nba.com/stats/players/usage?TeamID=1610612743&dir=A&sort=MIN",
  "Detroit Pistons": "https://www.nba.com/stats/players/usage?TeamID=1610612765&dir=A&sort=MIN",
  "Golden State Warriors": "https://www.nba.com/stats/players/usage?TeamID=1610612744&dir=A&sort=MIN",
  "Houston Rockets": "https://www.nba.com/stats/players/usage?TeamID=1610612745&dir=A&sort=MIN",
  "Indiana Pacers": "https://www.nba.com/stats/players/usage?TeamID=1610612754&dir=A&sort=MIN",
  "LA Clippers": "https://www.nba.com/stats/players/usage?TeamID=1610612746&dir=A&sort=MIN",
  "Los Angeles Lakers": "https://www.nba.com/stats/players/usage?TeamID=1610612747&dir=A&sort=MIN",
  "Memphis Grizzlies": "https://www.nba.com/stats/players/usage?TeamID=1610612763&dir=A&sort=MIN",
  "Miami Heat": "https://www.nba.com/stats/players/usage?TeamID=1610612748&dir=A&sort=MIN",
  "Milwaukee Bucks": "https://www.nba.com/stats/players/usage?TeamID=1610612749&dir=A&sort=MIN",
  "Minnesota Timberwolves": "https://www.nba.com/stats/players/usage?TeamID=1610612750&dir=A&sort=MIN",
  "New Orleans Pelicans": "https://www.nba.com/stats/players/usage?TeamID=1610612740&dir=A&sort=MIN",
  "New York Knicks": "https://www.nba.com/stats/players/usage?TeamID=1610612752&dir=A&sort=MIN",
  "Oklahoma City Thunder": "https://www.nba.com/stats/players/usage?TeamID=1610612760&dir=A&sort=MIN",
  "Orlando Magic": "https://www.nba.com/stats/players/usage?TeamID=1610612753&dir=A&sort=MIN",
  "Philadelphia 76ers": "https://www.nba.com/stats/players/usage?TeamID=1610612755&dir=A&sort=MIN",
  "Phoenix Suns": "https://www.nba.com/stats/players/usage?TeamID=1610612756&dir=A&sort=MIN",
  "Portland Trail Blazers": "https://www.nba.com/stats/players/usage?TeamID=1610612757&dir=A&sort=MIN",
  "Sacramento Kings": "https://www.nba.com/stats/players/usage?TeamID=1610612758&dir=A&sort=MIN",
  "San Antonio Spurs": "https://www.nba.com/stats/players/usage?TeamID=1610612759&dir=A&sort=MIN",
  "Toronto Raptors": "https://www.nba.com/stats/players/usage?TeamID=1610612761&dir=A&sort=MIN",
  "Utah Jazz": "https://www.nba.com/stats/players/usage?TeamID=1610612762&dir=A&sort=MIN",
  "Washington Wizards": "https://www.nba.com/stats/players/usage?TeamID=1610612764&dir=A&sort=MIN"
};

const weights = {
  "MIN": 0.75,
  "USG%": 0.85,
  "%PTS": 0.75,
  "%FGM": 0.45,
  "%FGA": 0.25,
  "%3PM": 0.2,
  "%3PA": 0.2,
  "%FTM": 0.2,
  "%FTA": 0.2,
  "%OREB": 0.28,
  "%DREB": 0.28,
  "%REB": 0.25,
  "%AST": 0.35,
  "%TOV": 0.2,
  "%STL": 0.22,
  "%BLK": 0.28,
  "%BLKA": 0.2,
  "%PF": 0.2,
  "%PFD": 0.2
};



function PlayerSticker({ playerName, position, status }) {

  


  
  const getStatusColor = (status) => {
    const lowerCaseStatus = status.toLowerCase();

    if (lowerCaseStatus === 'healthy') {
      return 'green';
    } else if (lowerCaseStatus === 'out') {
      return 'red'; // You can change this to any desired color
    } else {
      return 'yellow';
    }
  };

  return (
    <div className='playerSticker'>
      <div className='playerInfo'>

        <div className='playerName'>{playerName}</div>
        <div className='wrapper'>
          <div className='playerPosition'>{position}</div>
          <div className='divider' >•</div>
          <div className='playerStatus' style={{ color: getStatusColor(status) }}>{status}</div>

        </div>
      </div>
    </div>
  );
}

export default PlayerSticker;
