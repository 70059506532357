import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import PredictionCard from './predictionCard';


const teamAbbreviations = {
  "Atlanta Hawks": "ATL",
  "Boston Celtics": "BOS",
  "Brooklyn Nets": "BKN",
  "Charlotte Hornets": "CHA",
  "Chicago Bulls": "CHI",
  "Cleveland Cavaliers": "CLE",
  "Dallas Mavericks": "DAL",
  "Denver Nuggets": "DEN",
  "Detroit Pistons": "DET",
  "Golden State Warriors": "GSW",
  "Houston Rockets": "HOU",
  "Indiana Pacers": "IND",
  "LA Clippers": "LAC",
  "Los Angeles Lakers": "LAL",
  "Memphis Grizzlies": "MEM",
  "Miami Heat": "MIA",
  "Milwaukee Bucks": "MIL",
  "Minnesota Timberwolves": "MIN",
  "New Orleans Pelicans": "NOP",
  "New York Knicks": "NYK",
  "Oklahoma City Thunder": "OKC",
  "Orlando Magic": "ORL",
  "Philadelphia 76ers": "PHI",
  "Phoenix Suns": "PHX",
  "Portland Trail Blazers": "POR",
  "Sacramento Kings": "SAC",
  "San Antonio Spurs": "SAS",
  "Toronto Raptors": "TOR",
  "Utah Jazz": "UTA",
  "Washington Wizards": "WAS",
};

const teamFULLAbbreviations = {
  "ATL": "Atlanta Hawks",
  "BOS": "Boston Celtics",
  "BKN": "Brooklyn Nets",
  "CHA": "Charlotte Hornets",
  "CHI": "Chicago Bulls",
  "CLE": "Cleveland Cavaliers",
  "DAL": "Dallas Mavericks",
  "DEN": "Denver Nuggets",
  "DET": "Detroit Pistons",
  "GSW": "Golden State Warriors",
  "HOU": "Houston Rockets",
  "IND": "Indiana Pacers",
  "LAC": "LA Clippers",
  "LAL": "Los Angeles Lakers",
  "MEM": "Memphis Grizzlies",
  "MIA": "Miami Heat",
  "MIL": "Milwaukee Bucks",
  "MIN": "Minnesota Timberwolves",
  "NOP": "New Orleans Pelicans",
  "NYK": "New York Knicks",
  "OKC": "Oklahoma City Thunder",
  "ORL": "Orlando Magic",
  "PHI": "Philadelphia 76ers",
  "PHX": "Phoenix Suns",
  "POR": "Portland Trail Blazers",
  "SAC": "Sacramento Kings",
  "SAS": "San Antonio Spurs",
  "TOR": "Toronto Raptors",
  "UTA": "Utah Jazz",
  "WAS": "Washington Wizards"
};






function App() {
  const [dumpedScores, setDumpedScores] = useState(null);
  const [teamsData, setTeamsData] = useState(null);
  const [injuriesData, setInjuriesData] = useState(null);
  const [matchupAbbreviations, setmatchupAbbreviations] = useState(null);
  const [modelScores, setModelScores] = useState(null);
  const [injuryReport, setInjuryReport] = useState(null);

  function getScores(gameData){
    const games = gameData.map(game => {
      const { HomeTeam, AwayTeam, HomePredictedScore, AwayPredictedScore, date, gameid } = game;
      const homeTeamAbbr = teamAbbreviations[HomeTeam] || HomeTeam;
      const awayTeamAbbr = teamAbbreviations[AwayTeam] || AwayTeam;
      
      return [[HomePredictedScore], [AwayPredictedScore]];
      
    });
    console.log(games)
    return games;
  }
  function convertToAbbreviation(gameData) {
    const games = gameData.map(game => {
   
      const { HomeTeam, AwayTeam, HomePredictedScore, AwayPredictedScore, date, gameid } = game;
      const homeTeamAbbr = teamAbbreviations[HomeTeam] || HomeTeam;
      const awayTeamAbbr = teamAbbreviations[AwayTeam] || AwayTeam;
      
      return [[homeTeamAbbr], [awayTeamAbbr]];
      
    });
    
    return games;
  }

  function generateInjuryReport(gameData, teamsData, injuriesData, teamAbbreviations) {
    try {
      const injuryReport = {};




      convertToAbbreviation(gameData).forEach(matchup => {
        matchup.forEach(teams => {
          const [homeTeamAbbr, awayTeamAbbr] = teams;


          const homeTeamFullName = teamFULLAbbreviations[homeTeamAbbr]

          const awayTeamFullName = teamFULLAbbreviations[awayTeamAbbr]

          const homeTeamRoster = teamsData[homeTeamFullName] || [];
          const awayTeamRoster = teamsData[awayTeamFullName] || [];

          const homeTeamInjuries = injuriesData[homeTeamFullName] || [];
          const awayTeamInjuries = injuriesData[awayTeamFullName] || [];

          function getValueByKey(key, dictionary) {
            if (dictionary.hasOwnProperty(key)) {
              return dictionary[key];
            } else {
              return 'Healthy'; // Return null if the key is not found in the dictionary
            }
          }

          function getPlayerInjuryInfo(playerName, data) {
            for (let i = 0; i < data.length; i++) {
              const playerObject = data[i];
              const playerNameInData = Object.keys(playerObject)[0]; // Get the player's name from the current object
              if (playerNameInData === playerName) {
                return playerObject[playerNameInData];

              }
            }
            return 'Healthy'; // Return null if the player is not found
          }


          //('Hometeam injuries:',homeTeamInjuries)
          //('home',homeTeamRoster)

          const homeTeamWithInjuries = homeTeamRoster.map(player => {
            const playerName = player['Player'];
            const playerInfo = player[playerName];
            const hinjuryInfo = getPlayerInjuryInfo(playerName, homeTeamInjuries)
            //('Injury Info:', hinjuryInfo);
            const status = hinjuryInfo ? getValueByKey('Status', hinjuryInfo) : 'Healthy';
            // ('Status:', status);
            return { [playerName]: { ...playerInfo, Injury: { Status: status } } };
          });


          const awayTeamWithInjuries = awayTeamRoster.map(player => {
            const playerName = player['Player'];
            const playerInfo = player[playerName];
            const ainjuryInfo = getPlayerInjuryInfo(playerName, awayTeamInjuries)
            //('Injury Info:', ainjuryInfo);
            const status = ainjuryInfo ? getValueByKey('Status', ainjuryInfo) : 'Healthy';

            //('Status:', status);
            return { [playerName]: { ...playerInfo, Injury: { Status: status } } };
          });


          injuryReport[homeTeamFullName] = homeTeamWithInjuries
          injuryReport[awayTeamFullName] = awayTeamWithInjuries




        });
      });

      return injuryReport;
    }
    catch (error) {
      console.error(error)
    }


  }



  useEffect(() => {
    const fetchJSONData = async () => {

       // Get the API key from the environment variable
       const apiKey = "033464f367yu9476f768bdabd005e0X";

      // Define the URLs of your Flask app endpoints
      const modelScoresURL = `https://my-flask-app-srdwv4gzqq-uc.a.run.app/model-scores?api_key=${apiKey}`;
      const rostersURL = `https://my-flask-app-srdwv4gzqq-uc.a.run.app/rosters?api_key=${apiKey}`;
      const injuriesURL = `https://my-flask-app-srdwv4gzqq-uc.a.run.app/injuries?api_key=${apiKey}`;
      

     

   
    

      try {
        const responses = await Promise.all([
          axios.get(modelScoresURL),
          axios.get(rostersURL),
          axios.get(injuriesURL)
        ]);

        const [gamesResponse, teamsResponse, injuriesResponse] = responses;

     

        if (!gamesResponse.status === 200 || !teamsResponse.status === 200 || !injuriesResponse.status === 200) {
          throw new Error('One or more network responses were not ok');
        }

        const gamesData = gamesResponse.data;
        const teamsData = teamsResponse.data;
        const injuriesData = injuriesResponse.data;

        // Update the state after successfully fetching the data
        setDumpedScores(gamesData);
        setTeamsData(teamsData);
        setInjuriesData(injuriesData);

        // Set other states dependent on the fetched data
        if (gamesData) {
          const matchupAbbreviations = convertToAbbreviation(gamesData);
          setmatchupAbbreviations(matchupAbbreviations);
          const scores = getScores(gamesData);
          console.log('scores', scores)
          setModelScores(scores)
   
        }

        // Generate and set injury reports if all required data is available
        if (gamesData && teamsData && injuriesData) {
          const rosters = generateInjuryReport(gamesData, teamsData, injuriesData);
          setInjuryReport(rosters);
        }

        // Log fetched data for debugging
        //(':', gamesData, teamsData, injuriesData);
      } catch (error) {
        console.error('Error fetching JSON data:', error);
        // Optionally set an error state or display an error message to the user
      }
    };

    fetchJSONData();
  }, []);




  return (
    <div className="App">
      <header className="App-header">
        <div className='Date-Section'>
          <p className='centered-date'>Thursday, December 28th</p>
        </div>

        {/* Prediction Cards Section */}
        <div className='predictionCardsSection'>
          <h2 className='p-title'>Today's Projections</h2>
          {/* Viewport for Prediction Cards */}
          <div className='predictionCardsViewport'>
            {matchupAbbreviations &&
              matchupAbbreviations.map((matchup, index) => {
                const homeTeamAbbreviation = matchup[0][0];
                const awayTeamAbbreviation = matchup[1][0];





                const homeTeamFullName = teamFULLAbbreviations[homeTeamAbbreviation];


                const awayTeamFullName = teamFULLAbbreviations[awayTeamAbbreviation];


                const homeTeamInjuryReport = injuryReport[homeTeamFullName] || [];

                const awayTeamInjuryReport = injuryReport[awayTeamFullName] || [];



                return (
                  <div className='flexContainer' key={index}>
                    <PredictionCard
                      MatchupAbbreviations={matchup}
                      Scores={modelScores[index]}
                      HomeTeamInjuryReport={homeTeamInjuryReport}
                      AwayTeamInjuryReport={awayTeamInjuryReport}
                    />
                  </div>
                );
              })}
          </div>


        </div>

      </header>
    </div>
  );
}

export default App;
