import React, { useState, useEffect } from 'react';
import './predictionCard.css';
import * as NBAIcons from '../src/react-nba-logos';
import PlayerSticker from './PlayerSticker';

const teamColors = {
    ATL: ['#E03A3E', '#C1D32F'], // Atlanta Hawks - Red, Volt Green
    BKN: ['#000000', '#FFFFFF'], // Brooklyn Nets - Black, White
    BOS: ['#007A33', '#BA9653'], // Boston Celtics - Green, Gold
    CHA: ['#1D1160', '#00788C'], // Charlotte Hornets - Purple, Teal
    CHI: ['#CE1141', '#000000'], // Chicago Bulls - Red, Black
    CLE: ['#6F263D', '#FFB81C'], // Cleveland Cavaliers - Wine, Gold
    DAL: ['#00538C', '#002B5E'], // Dallas Mavericks - Royal Blue, Navy Blue
    DEN: ['#0E2240', '#FEC524'], // Denver Nuggets - Midnight Blue, Gold
    DET: ['#C8102E', '#006BB6'], // Detroit Pistons - Red, Blue
    GSW: ['#1D428A', '#FFC72C'], // Golden State Warriors - Royal Blue, Gold
    HOU: ['#CE1141', '#000000'], // Houston Rockets - Red, Black
    IND: ['#002D62', '#FDBB30'], // Indiana Pacers - Navy Blue, Gold
    LAC: ['#C8102E', '#1D428A'], // LA Clippers - Red, Blue
    LAL: ['#552583', '#FDB927'], // Los Angeles Lakers - Purple, Gold
    MEM: ['#5D76A9', '#12173F'], // Memphis Grizzlies - Beale Street Blue, Beale Street Navy
    MIA: ['#98002E', '#F9A01B'], // Miami Heat - Red, Gold
    MIL: ['#00471B', '#EEE1C6'], // Milwaukee Bucks - Hunter Green, Cream
    MIN: ['#0C2340', '#78BE20'], // Minnesota Timberwolves - Navy Blue, Lime Green
    NOP: ['#0C2340', '#85714D'], // New Orleans Pelicans - Navy Blue, Gold
    NYK: ['#006BB6', '#F58426'], // New York Knicks - Blue, Orange
    OKC: ['#007AC1', '#EF3B24'], // Oklahoma City Thunder - Blue, Orange
    ORL: ['#0077C0', '#C4CED4'], // Orlando Magic - Blue, Silver
    PHI: ['#006BB6', '#ED174C'], // Philadelphia 76ers - Blue, Red
    PHX: ['#1D1160', '#E56020'], // Phoenix Suns - Purple, Orange
    POR: ['#E03A3E', '#000000'], // Portland Trail Blazers - Red, Black
    SAC: ['#5A2D81', '#63727A'], // Sacramento Kings - Purple, Gray
    SAS: ['#C4CED4', '#000000'], // San Antonio Spurs - Silver, Black
    TOR: ['#CE1141', '#000000'], // Toronto Raptors - Red, Black
    UTA: ['#002B5C', '#00471B'], // Utah Jazz - Navy Blue, Green
    WAS: ['#002B5C', '#E31837'], // Washington Wizards - Navy Blue, Red
};

function PredictionCard({ MatchupAbbreviations, Scores, HomeTeamInjuryReport, AwayTeamInjuryReport }) {

    const [playerData, setPlayerData] = useState([]);


   
    // Function to determine player importance
    const determinePlayerImportance = (playerName) => {
      
        let playerImportance = 0;
        let playerFound = false;
    
        // Loop through each team in playerData
        for (const team in playerData) {
            const playersInTeam = playerData[team];
    
            // Loop through each player in the team
            for (const player of playersInTeam) {
                if (player.player === playerName) {
                    playerImportance = player.importance;
                    playerFound = true;
                    break; // Stop searching once the player is found
                }
            }
    
            if (playerFound) {
                console.log(`Player ${playerName} found in team ${team}`);
                break; // Stop searching if the player is found
            }
        }
    
        if (!playerFound) {
            console.log(`Player ${playerName} not found`);
        }
    
        return playerImportance;
    };




    const sortedAwayTeamInjuryReport = AwayTeamInjuryReport.sort((a, b) => {
        const playerA = Object.keys(a)[0];
        const playerB = Object.keys(b)[0];
        const importanceA = determinePlayerImportance(playerA);
        const importanceB = determinePlayerImportance(playerB);
        return importanceB - importanceA;
    });

    const sortedHomeTeamInjuryReport = HomeTeamInjuryReport.sort((a, b) => {
        const playerA = Object.keys(a)[0];
        const playerB = Object.keys(b)[0];
        const importanceA = determinePlayerImportance(playerA);
        const importanceB = determinePlayerImportance(playerB);
        return importanceB - importanceA;
    });






    // (HomeTeamInjuryReport)
    const [homeAbbr, awayAbbr] = MatchupAbbreviations;

    const h_a = homeAbbr[0];
    const a_a = awayAbbr[0];
    const home_sc = parseInt(Scores[0]);
  
    const away_sc = parseInt(Scores[1]);

    //d(homeAbbr)
    const logo_size = 80;

    const TeamIcons = {
        ATL: NBAIcons.ATL,
        BKN: NBAIcons.BKN,
        BOS: NBAIcons.BOS,
        CHA: NBAIcons.CHA,
        CHI: NBAIcons.CHI,
        CLE: NBAIcons.CLE,
        DAL: NBAIcons.DAL,
        DEN: NBAIcons.DEN,
        DET: NBAIcons.DET,
        GSW: NBAIcons.GSW,
        HOU: NBAIcons.HOU,
        IND: NBAIcons.IND,
        LAC: NBAIcons.LAC,
        LAL: NBAIcons.LAL,
        MEM: NBAIcons.MEM,
        MIA: NBAIcons.MIA,
        MIL: NBAIcons.MIL,
        MIN: NBAIcons.MIN,
        NOP: NBAIcons.NOP,
        NYK: NBAIcons.NYK,
        OKC: NBAIcons.OKC,
        ORL: NBAIcons.ORL,
        PHI: NBAIcons.PHI,
        PHX: NBAIcons.PHX,
        POR: NBAIcons.POR,
        SAC: NBAIcons.SAC,
        SAS: NBAIcons.SAS,
        TOR: NBAIcons.TOR,
        UTA: NBAIcons.UTA,
        WAS: NBAIcons.WAS,
    };


    const HomeTeamIcon = TeamIcons[h_a];
    const AwayTeamIcon = TeamIcons[a_a];

    const homeTeamColors = teamColors[homeAbbr[0]];
    const awayTeamColors = teamColors[awayAbbr[0]];



    return (
        <div className='PredictionCard'>
            {/* Home Team Section */}
            <div className='Container'>
                <div className='Home' style={{ background: `linear-gradient(to right, ${homeTeamColors[0]}, ${homeTeamColors[1]})` }}>
                    {/* ... Home team content */}
                    <div className='scorenlogo_home'>
                        <div className='teamSection'>
                            <div className='teamLogo'>
                                {HomeTeamIcon && <HomeTeamIcon size={60} />}

                            </div>

                        </div>
                        <div className='scoreHome' >{home_sc}</div>



                    </div>
                    <div className='locationIndicator'>Home</div>

                    <div className="scrollable-viewport">
                        {sortedHomeTeamInjuryReport.map(player => (
                            <PlayerSticker
                                key={Object.keys(player)[0]} // Use the player's name as the key
                                playerName={Object.keys(player)[0]} // Get the player's name
                                position={player[Object.keys(player)[0]].Pos} // Get the player's position
                                status={
                                    player[Object.keys(player)[0]].Injury.Status === 'Healthy'
                                        ? 'healthy'
                                        : player[Object.keys(player)[0]].Injury.Status === 'Game Time Decision'
                                            ? 'Questionable'
                                            : player[Object.keys(player)[0]].Injury.Status === 'Out'
                                                ? 'Out'
                                                : 'inactive'
                                }
                            />
                        ))}
                    </div>

                </div>

                {/* Away Team Section */}
                <div className='Away' style={{ background: `linear-gradient(to left, ${awayTeamColors[0]}, ${awayTeamColors[1]})` }}>
                    <div className='scorenlogo_away'>
                        <div className='scoreAway' >{away_sc}</div>
                        <div className='teamSection'>

                            <div className='teamLogo'>
                                {AwayTeamIcon && <AwayTeamIcon size={60} />}
                            </div>

                        </div>




                    </div>
                    <div className='locationIndicator'>Away</div>
                    <div className="scrollable-viewport">
                        {sortedAwayTeamInjuryReport.map(player => (
                            <PlayerSticker
                                key={Object.keys(player)[0]} // Use the player's name as the key
                                playerName={Object.keys(player)[0]} // Get the player's name
                                position={player[Object.keys(player)[0]].Pos} // Get the player's position
                                status={
                                    player[Object.keys(player)[0]].Injury.Status === 'Healthy'
                                        ? 'healthy'
                                        : player[Object.keys(player)[0]].Injury.Status === 'Game Time Decision'
                                            ? 'Questionable'
                                            : player[Object.keys(player)[0]].Injury.Status === 'Out'
                                                ? 'Out'
                                                : 'inactive'
                                }
                            />
                        ))}
                    </div>
                </div>

            </div>
        </div>
    );
}






export default PredictionCard;